import React, { useEffect, useLayoutEffect, useState } from 'react'
import Sidenav from '../Sidenav';
import Avatar from '../../assets/avatar.svg'
import { useDispatch, useSelector } from 'react-redux';
import { get, post } from '../../utils/axiosInstance';
import { setBookedDates } from '../../state';
import StepNav from './StepNav';
import VendorList from './VendorList';
import VersusVendor from './VersusVendor';
import LoadingButton from '../LoadingButton';
import { toast, ToastContainer } from 'react-toastify';

function CompareVendor() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const [vendors, setVendors] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [comparisonData, setComparisonData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useLayoutEffect(() => {
    const fetchVendors = async () => {
      setSelectedVendors([])
      setLoading(true);
      try {
        const res = await get('/vendor', {}, {}, Token);
        if ("response" in res) {
          const response = res.response?.data?.vendors;
          dispatch(setBookedDates([]));
          setVendors(response);
        }
      } catch (err) {
        console.error("Error fetching vendors:", err);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchVendors();
  }, []);


  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleVendorSelection = (vendor) => {
    if (selectedVendors.includes(vendor)) {
      setSelectedVendors(selectedVendors.filter((v) => v !== vendor));
    } else {
      if (selectedVendors.length < 3) {
        setSelectedVendors([...selectedVendors, vendor]);
      } else {
        alert('You can only select up to 3 vendors.');
      }
    }
  };
  const handleContinue = async () => {
    if (selectedVendors.length >= 2) {
      setIsProcessing(true);
      const selectedIds = selectedVendors.map(v => v.id);
      try {
        const res = await post(`/vendor/compare`, { vendor_ids: selectedIds }, {}, Token);
        if ("response" in res) {
          const comparisonData = res.response?.data.vendors;
          setComparisonData(comparisonData);
          setCurrentStep(2);
          setIsProcessing(false);
        }
      } catch (error) {
        console.error("Error fetching comparison data:", error);
      } finally {
        setIsProcessing(false);
      }
    } else {
      toast("There must be atleast two vendors for comparison", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        type: "warning",
      });
    }
  };
  const _renderSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='mx-4 mt-4'>
            {
              vendors && (
                <VendorList
                  vendors={vendors}
                  selectedVendors={selectedVendors}
                  handleVendorSelection={handleVendorSelection}
                  loading={loading}
                />
              )
            }
          </div>
        );
        break;
      case 2:
        return (
          <VersusVendor data={comparisonData} />
        )
      default:
        break;
    }
  }
  return (
    <div className="w-full flex flex-row">
      <div className="lg:w-[9%] md:w-[12%]">
        <Sidenav selectedOne={1} />
      </div>
      <div className="w-[100%] flex flex-col">
        <StepNav steps={stepsData} currentStep={currentStep} toggleDropdown={toggleDropdown} />
        {_renderSteps()}
        <div className="mt-6 flex justify-between p-10">
          {
            currentStep > 1 && (
              <button
                className={`px-6 py-2 rounded-lg text-white 
              ${selectedVendors.length >= 2 && selectedVendors.length <= 3 ? 'bg-gray-400' : 'bg-gray-300 cursor-not-allowed'}`}
                disabled={selectedVendors.length < 2 || selectedVendors.length > 3}
                onClick={prevStep}
              >
                Back
              </button>
            )
          }
          {
            currentStep == 1 && (
              <LoadingButton
                isLoading={isProcessing}
                onClick={handleContinue}
              >
                Continue
              </LoadingButton>
            )}
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
}

export default CompareVendor

const stepsData = [
  { title: 'Select Vendor', description: 'select vendor for comparison' },
  { title: 'Compare', description: 'Compare and select vendor as default' },
];