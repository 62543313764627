import React, { useState } from "react";
import moment from "moment";
import { SummaryAggrementmodal } from "../Modals/SummaryAggrementmodal";

const Reservationdatacard = ({ apiData }) => {
    const summaryData = apiData?.tax_and_charges;
    const start = moment(apiData.start_date).format('MM/DD/YYYY');
    const end = moment(apiData.end_date).format('MM/DD/YYYY');
    const startDate = moment(start, 'MM/DD/YYYY');
    const endDate = moment(end, 'MM/DD/YYYY');
    const daysDifference = endDate.diff(startDate, 'days') + 1;
    const [couponValue, setCouponValue] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [agreementCheck, setAgreementCheck] = useState(false);
    const [responseSignatureData, setResponseSignatureData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [selectedOptionalItems, setSelectedOptionalItems] = useState([]);
    const [couponValueCal, setCouponValueCal] = useState(0);
    const handleResponseSignatureData = (signatureData) => {
        setResponseSignatureData(signatureData);
    };

    const handleResponseData = (data) => {
        setResponseData(data);
    };
    const openModal = () => {
        setShowModal(true);
    };
    console.log('====================================');
    console.log("apiData", apiData);
    console.log('====================================');
    // const Value = [
    //     {
    //         title: "Payment Method",
    //         content: apiData && apiData?.bookings[0]?.payment_method,
    //     },
    //     {
    //         title: "Coupon",
    //         content: apiData && apiData?.coupon?.description,
    //     },
    //     {
    //         title: "Address",
    //         content: apiData && apiData?.bookings[0]?.address_details,
    //     },
    //     {
    //         title: "Main Reservation Date",
    //         // content: `${start_date.format("DD MMM YYYY")} - ${end_date.format("DD MMM YYYY")}`,
    //     },
    // ];

    localStorage.setItem("apiData", apiData.signature)

    let discount = apiData?.discounts;
    let duration = 0
    let coupon = apiData?.coupon

    const checkType = (item) => {
        return item.type === "percent" ? `${item.value}%` : `$${item.value}`
    }
    const getDuration = () => {
        const durationValues = apiData?.booking_details.map(item => parseInt(item.duration, 10));
        return durationValues.reduce((sum, duration) => sum + duration, 0);
    }
    const calculateTotal = () => {
        let cartsTotal = apiData?.booking_details?.filter(item => item.vehicle.price_perday > 0).reduce((acc, item) => { return acc + item.vehicle.price_perday }, 0);
        let totalInitAmount = apiData.sub_amount;
        let initialAmountWithoutDiscount = 0;
        let initAmount = 0;
        let subTotalAmount = 0;
        let tempSubTotal = 0;
        let subTotalCalculation = 0;
        let totalWithTaxAmount = 0;
        let beforeFinalTotal = 0;
        let finalTotal = 0;
        let couponAmount = 0;

        apiData?.tax_and_charges?.forEach((category) => {
            if (category.calculate_on === "init_amount") {
                if (category.operation_type === "percent") {
                    initAmount += (totalInitAmount * category.value) / 100;
                } else if (category.operation_type === "amount") {
                    initAmount += category.value;
                }
            }
        });

        totalInitAmount += initAmount;
        initialAmountWithoutDiscount = totalInitAmount;

        if (discount) {
            if (discount.operation_type === "percent") {
                totalInitAmount -= (totalInitAmount * discount.value) / 100;
            } else if (discount.operation_type === "amount") {
                totalInitAmount -= discount.value;
            }
        }

        subTotalAmount = totalInitAmount;
        subTotalCalculation = subTotalAmount;

        apiData?.tax_and_charges?.forEach((category) => {
            if (category.calculate_on === "subtotal") {
                if (category.operation_type === "percent") {
                    tempSubTotal += (subTotalAmount * category.value) / 100;
                } else if (category.operation_type === "amount") {
                    tempSubTotal += category.value;
                }
            }
        });

        totalWithTaxAmount = (subTotalAmount + tempSubTotal);
        beforeFinalTotal = totalWithTaxAmount
        // Calculate total_with_tax taxes and charges
        apiData?.tax_and_charges?.forEach((category) => {
            if (category.calculate_on === "total_with_tax") {
                if (category.operation_type === "percent") {
                    totalWithTaxAmount += (totalWithTaxAmount * category.value) / 100;
                } else if (category.operation_type === "amount") {
                    totalWithTaxAmount += category.value;
                }
            }
        });

        finalTotal = totalWithTaxAmount;

        if (apiData.coupon) {
            if (apiData.coupon.operation === "percentage") {
                couponAmount = (finalTotal * apiData.coupon.value) / 100;
            } else if (apiData.coupon.operation === "amount") {
                couponAmount = apiData.coupon.value;
            }
        }
        // Return the calculated values
        return {
            cartsTotal,
            totalInitAmount,
            initialAmountWithoutDiscount,
            subTotalAmount,
            totalWithTaxAmount,
            finalTotal,
            subTotalCalculation,
            beforeFinalTotal,
            couponAmount
        };
    };
    const isMandatory = (periority) => periority === "mandatory";
    let sum = 0;
    let taxId = [];
    let discounts = apiData.discounts;
    const renderItems = () => {
        if (!summaryData) return null;
        return (
            <div>
                {
                    summaryData.filter((i) => i.calculate_on == "init_amount").map((category) => {
                        let sums = 0;
                        if (category.operation_type === "percent") {
                            sums = (apiData.sub_amount * category.value) / 100;
                        } else if (category.operation_type === "amount") {
                            sums = category.value;
                        }
                        return (
                            <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                                <div>
                                    <div key={category.id} className="flex justify-between">
                                        <p className="text-Textlitecolor py-[2px] text-start">
                                            - {category.name} {category.operation_type === "percent" && `(${category.value}%)`} = {"$" + sums.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
                    <p className="">Sub Total</p>
                    <p className="">{`$${parseFloat(
                        calculateTotal().initialAmountWithoutDiscount
                    ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`}</p>
                </div>
                {discounts && (
                    <div className="py-[2px]">
                        <div>
                            <div className="flex justify-start md:text-[16px] text-[12px] gap-1">
                                <p className="text-Textlitecolor  text-start">- Discount</p>
                                <p className="text-Textlitecolor  text-start ">
                                    ($
                                    {(discounts =
                                        discount.operation_type === "percent"
                                            ? (
                                                (calculateTotal().initialAmountWithoutDiscount * discount.value) /
                                                100
                                            ).toFixed(2)
                                            : discount.value.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }))})
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {
                    summaryData.filter((i) => i.calculate_on == "subtotal").map((category) => {
                        let sums = 0;
                        if (category.operation_type === "percent") {
                            sums = (calculateTotal().subTotalCalculation * category.value) / 100;
                        } else if (category.operation_type === "amount") {
                            sums = category.value;
                        }
                        return (
                            <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                                <div>
                                    <div key={category.id} className="flex justify-between">
                                        <p className="text-Textlitecolor py-[2px] text-start">
                                            - {category.name} {category.operation_type === "percent" && `(${category.value}%)`} = {"$" + sums.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
                    <p className="">Total With Tax</p>
                    <p className="">{`$${parseFloat(
                        calculateTotal().beforeFinalTotal
                    ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`}</p>
                </div>
                {
                    summaryData.filter((i) => i.calculate_on == "total_with_tax").map((category) => {
                        let sums = 0;
                        if (category.operation_type === "percent") {
                            sums = (calculateTotal().beforeFinalTotal * category.value) / 100;
                        } else if (category.operation_type === "amount") {
                            sums = category.value;
                        }
                        return (
                            <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                                <div>
                                    <div key={category.id} className="flex justify-between">
                                        <p className="text-Textlitecolor py-[2px] text-start">
                                            - {category.name} {category.operation_type === "percent" && `(${category.value}%)`} = {"$" + sums.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {apiData?.coupon && (
                    <div className="py-1">
                        <div>
                            <div className="flex justify-between md:text-[16px] text-[12px]">
                                <p className="text-[#e6a62f] font-semibold">Coupon</p>
                                <p className="text-[#e6a62f] font-semibold">
                                    {apiData?.coupon.operation === "percentage"
                                        ? `$${calculateTotal().couponAmount.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`
                                        : `$${calculateTotal().couponAmount.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`}
                                    {/* {calculateTotal().couponAmount} */}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
                    <p className="">Total To Customer</p>
                    <p className="">{`$${parseFloat(apiData?.total_amount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`}</p>
                </div>
            </div>
        )
    };
    return (
        <>
            <div className="my-4 py-4">
                <div className={`bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] mx-4 py-4 border-black-600 text-center`}  >
                    <div>
                        <>
                            <p className="text-Textdarkcolor flex  px-5 text-[16px]">LSV Vendor</p>
                            <div className={`justify-start flex flex-col text-start p-5`}>
                                <p className=" md:text-[16px] text-[14px]">Title: <span className="text-Appcolor py-3  md:text-[16px] text-[1
                                    3px]">{apiData.vendor?.title}</span></p>
                                <p className="my-3  md:text-[16px] text-[14px]">Location: <span className="text-Appcolor  md:text-[16px] text-[13px] first-letter:">{apiData.vendor?.location}</span></p>

                                <p className=" md:text-[16px] text-[14px]">Description: <span className="text-Appcolor  md:text-[16px] text-[13px]"
                                    dangerouslySetInnerHTML={{
                                        __html: apiData.vendor?.description ?? "",
                                    }}
                                >
                                </span></p>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <div className="rounded-xl bg-[#00000014] text-center shadow-[0px_8px_32px_#00000014] border-black-600 mx-auto w-[95%]">
                <div className="flex pt-[24px] pb-[10px] w-[90%] md:w-[90%] mx-auto justify-between md:text-[16px] text-[13px] ">
                    <p className=" ">Total Selected Day(s)</p>
                    <p className="">{getDuration()}</p>
                </div>
                {
                    apiData.booking_details.map((i) => (
                        <div className="flex  w-[90%] md:w-[90%] mx-auto justify-between md:text-[16px] text-[13px] ">
                            <p className="text-Textlitecolor">{`Total Price (${parseInt(daysDifference - 1)} Days LSV ${getDuration()})`}</p>
                            <p className="">${i?.price * i.duration}</p>
                        </div>
                    ))
                }


                <div className="flex flex-col  md:text-[16px] text-[13px] w-[90%] mx-auto">
                    {renderItems()}
                </div>

            </div>
            <div className="w-[90%] mx-auto my-5">
                <div className="mt-5">
                    <div className="flex">
                        <input
                            id={1}
                            name={"checkbox"}
                            checked={true}
                            type={"checkbox"}
                            placeholder={"checkbox"}
                            readOnly
                        />
                        <label htmlFor={""} className={"pl-3 md:text-[16px] text-[12px] text-Textdarkcolor font-medium"}>
                            <span>
                                View the Vendor's
                                <a
                                    onClick={openModal}
                                    className={`underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600 `}
                                >
                                    Agreement.
                                </a>
                            </span>
                        </label>
                    </div>
                </div>
            </div>


            {/* <SummaryAggrementmodal
                showModal={showModal}
                setShowModal={setShowModal}
                setAgreementCheck={setAgreementCheck}
                onResponseData={handleResponseData}
                onResponseSignatureData={handleResponseSignatureData}
            /> */}
        </>
    );
};

export default Reservationdatacard;


