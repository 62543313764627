import React from "react";
import { useSelector } from "react-redux";

export function Confirmationmodal({
  showModal,
  setShowModal,
  selectedVendorId,
  onYesButtonClick,
  onNoButtonClick,
  name,
}) {
  const handleYesClick = () => {
    onYesButtonClick(selectedVendorId);
    setShowModal(false);
  };

  const handleNoClick = () => {
    onNoButtonClick(selectedVendorId);
    setShowModal(false);
  };



  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50">
            <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
              <div className="w-full max-w-lg bg-white shadow-lg rounded-md p-6 relative">
                <div onClick={() => setShowModal(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3.5 cursor-pointer shrink-0 fill-black hover:fill-red-500 float-right"
                    viewBox="0 0 320.591 320.591"
                  >
                    <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000"></path>
                    <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000"></path>
                  </svg>
                </div>
                <div className="my-8 text-center">
                  <h4 className="text-2xl font-bold mt-6">{name}</h4>
                  <h4 className="text-lg font-semibold mt-2">Do you want to set this as a preferred vendor?</h4>
                </div>
                <div className="text-center space-x-4">
                  <button type="button" onClick={handleNoClick} className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200">
                    No
                  </button>
                  <button type="button" onClick={handleYesClick} className="px-6 py-2.5 rounded-md text-white text-sm font-semibold border-none outline-none bg-Appcolor  ">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
