import React, { useEffect, useState } from "react";
import Reservationdatacard from "./Reservationdatacard";
import moment from "moment";

const Detailcard = ({ apiData }) => {
    const text = "Reservation Owner";
    const invitedUsers = apiData?.invited_user;


    const [invitedUsersExist, setInvitedUsersExist] = useState(false);


    useEffect(() => {
        setInvitedUsersExist(invitedUsers && invitedUsers.length > 0);
    }, [invitedUsers]);

    const generateValueArray = () => {
        const baseValue = [
            {
                title: "Address",
                content: [apiData.address_details],
            },
            {
                title: text,
                content: [
                    {
                        name: apiData?.booking_user?.first_name,
                        Email: apiData?.booking_user?.email,
                    },
                ],
            },
            {
                title: "Reservation Date",
                content: [
                    moment(apiData.start_date).format("DD MMM YYYY"),
                    "-",
                    moment(apiData.end_date).format("DD MMM YYYY"),
                ],
            },
        ];

        if (invitedUsersExist) {
            baseValue.push({
                title: "Additional Driver(s) Email",
                content: invitedUsers,
            });
        }

        return baseValue;
    };

    const Value = generateValueArray();

    return (
        <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%] h-[670px]">
            <div className="py-3 lg:w-[70%] w-[90%] custom-scrollbar">
                <form>
                    <div >
                        {apiData?.booking_details?.map((item, index) => {
                            return (
                                <div
                                    className="bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] m-4 py-4 border-black-600 text-center"
                                    key={index}
                                >
                                    <div className=" grid lg:grid-cols-3 lg:gap-5 gap-0 md:grid-cols-2 grid-cols-1 w-[90%] mx-auto">
                                        <div className="">
                                            <p className=" md:text-[16px] text-[14px]">Category</p>
                                            <p className="lg:mt-[24px] mt-[4px]  md:text-[16px] text-[13px] text-Inputtextcolor">
                                                {item.vehicle.cart_type}
                                            </p>
                                        </div>
                                        {/* <div>
                                            <p className="md:mt-0 mt-3  md:text-[16px] text-[14px]">Type</p>
                                            <p className="lg:mt-[24px] mt-[4px]  md:text-[16px] text-[13px] text-Inputtextcolor">
                                                {item.vehicle.drive}
                                            </p>
                                        </div> */}
                                        <div>
                                            <p className="md:mt-0 mt-3  md:text-[16px] text-[14px]">Passenger</p>
                                            <p className="lg:mt-[24px] mt-[4px]  md:text-[16px] text-[13px] text-Inputtextcolor">
                                                {item.vehicle.category.name}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="md:mt-0 mt-3  md:text-[16px] text-[14px]">Price per Day</p>
                                            <p className="lg:mt-[24px] mt-[4px]  md:text-[16px] text-[13px] text-Inputtextcolor">
                                                ${item.vehicle.price_perday}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="my-[20px] text-Appcolor   md:text-[16px] text-[14px]">
                                        {`${moment(item.start_date).format(
                                            "MMM, DD YYYY"
                                        )} - ${moment(item.end_date).format("MMM, DD YYYY")}`}
                                    </p>
                                </div>
                            );
                        })}
                    </div>



                    {Value.map((item, ind) => (
                        <div
                            key={ind}
                            className={`bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] m-4 py-4 border-black-600  text-center`}
                        >
                            <div>
                                <p className="text-Textdarkcolor flex px-5">{item?.title}</p>
                                <div className="flex p-5">
                                    {item?.title === text ? (
                                        <div>
                                            {item.content.map((person, index) => (
                                                <div key={index} className="text-start">

                                                    <p className="text-Textdarkcolor  md:text-[16px] text-[11px]">
                                                        Name:
                                                        <span className="text-Appcolor  md:text-[16px] text-[11px]">{`
                                                            ${""} ${person.name
                                                            }`}</span>
                                                    </p>
                                                    <p className="text-Textdarkcolor  md:text-[16px] text-[11px]">
                                                        Email:
                                                        <span className="text-Appcolor  md:text-[16px] text-[11px] ">{`
                                                           ${""} ${person.Email}`}</span>
                                                    </p>



                                                </div>
                                            ))}
                                        </div>
                                    ) : item.title === "Additional Driver(s) Email" ? (
                                        invitedUsersExist ? (
                                            <ul className="w-full">
                                                {invitedUsers.map((user, index) => (
                                                    <div className="flex justify-between w-full mt-1" key={index}>
                                                        <li className="text-Appcolor md:text-[16px] text-[11px]">{user?.email}</li>
                                                        <li
                                                            className={`${user?.status === "pending" ? "text-[#e6a62f] md:text-[16px] text-[11px]" : "text-green-600 md:text-[16px] text-[11px]"
                                                                } `}
                                                            key={index}
                                                        >
                                                            {user?.status}
                                                        </li>
                                                    </div>
                                                ))}
                                            </ul>
                                        ) : null
                                    ) : (
                                        <p className="text-Textdarkcolor  md:text-[16px] text-[14px]">{item.content}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}

                    <Reservationdatacard apiData={apiData} />
                </form>
            </div>
        </div>
    );
};

export default Detailcard;
